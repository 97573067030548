import React from 'react'
import * as PC from '@/constants/product-codes'

const sharedValueProps = {
    ecoFriendly: {
        title: 'Better for you & the planet',
        description (ScrollButton) {
            return <>Our eco-friendly <ScrollButton copy='CertiPUR-US®' accordionContent='accordion-content-certipur' /> certified high-density foams are made with renewable oils and produce fewer greenhouse gases during manufacturing. This guarantees that it’s low in volatile organic compounds (VOCs) to minimize off-gassing, and free of other potentially harmful chemicals.</>
        },
        hasScroll: true,
        svgId: 'icon-world-good'
    },
    secureFit: {
        title: 'Secure fit',
        description: 'Four elastic anchor bands ensure a secure fit to your mattress with no shifting.',
        svgId: 'icon-fit'
    },
    luxurious: {
        svgId: 'icon-luxury',
        title: 'Luxurious',
        list: [
            'Plush 3" Euro pillow top comes standard for cushioned comfort and pressure relief',
            'Available in three firmness levels for every sleep position and style'
        ]
    },
    durable: {
        svgId: 'icon-durable-mattress',
        title: 'Durable',
        list: [
            'Say goodbye to sagging with a responsive dual-coil design made up of over 1,000 recycled carbon steel coils (in full size and up)',
            'Tempered three times for superb durability'
        ]
    },
    supportive: {
        svgId: 'icon-supportive',
        title: 'Supportive',
        list: [
            'Get extra support where you need it most with our exclusive patented Lumbar Zone® Technology',
            'Enjoy healthy spinal alignment in any sleep position'
        ]
    },
    breathable: {
        svgId: 'icon-breathable-mattress',
        title: 'Breathable',
        list: [
            'Naturally breathable, hypoallergenic cover made with organic cotton that sleeps cooler',
            'Dual-coil design allows more airflow throughout to help disperse body heat'
        ]
    },
    ethicallySourcedCotton: {
        svgId: 'icon-world-good',
        title: 'Ethically sourced',
        description: 'Every step in crafting our cotton has been guided by our commitment to improving the livelihood of the workers who handcraft our bedding in India.'
    },
    pressureRelieving: {
        svgId: 'icon-pressure-relief',
        title: 'Pressure relieving',
        list: [
            'Proprietary 5-zone natural latex for excellent pressure relief and weightless comfort',
            'Enhanced support of the lumbar spine in any sleep position'
        ]
    },
    responsiveLatex: {
        svgId: 'icon-responsive',
        title: 'Responsive',
        hasScroll: true,
        list: [
            `Individually wrapped coils respond to body's movements and contours to every curve in any sleep position`,
            'Ergonomic firmness zones with enhanced support of the lumbar spine where you need it most'
        ]
    },
    excellentCooling: {
        svgId: 'icon-cooling',
        title: 'Excellent cooling',
        hasScroll: true,
        list: [
            'Naturally breathable, hypoallergenic cover made with organic cotton that sleeps cooler',
            'Natual latex is highly breathable and organic New Zealand wool helps regulate body temperature'
        ]
    },
    healthierSleep: {
        svgId: 'icon-healthier-sleep',
        title: 'Healthier sleep',
        list: [
            {
                description (ScrollButton) {
                    return <><ScrollButton copy='GOLS' accordionContent='accordion-content-gols' /> (Global Organic Latex Standard) certified to adhere to their strict limits on harmful chemicals, fillers, and emissions</>
                }
            },
            {
                description (ScrollButton) {
                    return <p><ScrollButton copy='GREENGUARD® Gold' accordionContent='accordion-content-greenguard' /> & <ScrollButton copy='eco-INSTITUT®' accordionContent='accordion-content-ecoinstitut' /> certified to have low VOCs (volatile organic compounds) and total chemical emissions</p>
                }
            }
        ],
        hasScroll: true
    },
    bodyContouring: {
        svgId: 'icon-supportive',
        title: 'Body-Contouring',
        list: [
            'Deep, body-contouring feel of a memory foam',
            'Great pressure-point relief for all sleep positions, especially side sleepers'
        ]
    },
    responsive: {
        svgId: 'icon-responsive',
        title: 'Responsive',
        list: [
            'Responsive feel of an innerspring with individually wrapped comfort coils for less motion transfer',
            'Greater ease of movement—never feel like you’re “locked in” to one position'
        ]
    },
    tripleCooling: {
        svgId: 'icon-cooling',
        title: 'Triple Cooling',
        list: [
            'Innovative cooling system prevents overheating common with regular memory foam',
            'Perfect for sleepers who love memory foam but want cooler sleep'
        ]
    },
    backSupport: {
        svgId: 'icon-lumbar-support',
        title: 'Back Support',
        list: [
            'Get extra support where you need it most with our patented Lumbar Zone® Technology',
            'Added lower back support and pressure relief with a gel-infused memory foam lumbar crown'
        ]
    },
    goodForTheWorld: {
        svgId: 'icon-world-good',
        hasScroll: true,
        title: 'Healthier sleep',
        list: [
            {
                description (ScrollButton) {
                    return <>Eco-friendly <ScrollButton copy='CertiPUR-US® certified' accordionContent='accordion-content-certipur' /> foams produce lower volatile organic compounds (VOCs) for healthier indoor air quality.</>
                }
            },
            'Certified free of potentially harmful chemicals like ozone depleters, formaldehyde, and heavy metals'
        ]
    },
    lightYetDurable: {
        svgId: 'icon-soft',
        title: 'Light yet durable',
        description: `Long cotton fibers are woven into a crisp percale weave that's naturally very breathable, moisture-wicking, and cool to the touch.`
    },
    rightThreadCount: {
        title: 'Just the right thread count',
        description: 'As thread counts rise, fabrics become warmer. We chose a thread count of 200 for the perfect balance of softness and breathability. Our percale cotton is pre-washed for a soft, lived-in feel.',
        svgId: 'icon-thread'
    },
    oekotex: {
        title: 'Healthier sleep',
        description (ScrollButton) {
            return <>We only use <ScrollButton copy='OEKO-TEX® Standard 100' accordionContent='accordion-content-what-is-oekotex' /> certified cotton that has passed safety tests for the presence of harmful substances. Natural cotton is also moisture-wicking and hypoallergenic.</>
        },
        hasScroll: true,
        svgId: 'icon-healthier-sleep'
    },
    rightThreadCount300: {
        title: `Just the right thread count`,
        description: `As thread counts rise, fabrics become warmer. We chose a thread count of 300 for the perfect balance of softness and breathability.`,
        svgId: 'icon-thread'
    },
    organicAllTheWay: {
        title: 'Organic all the way',
        description (ScrollButton) {
            return <><ScrollButton copy='GOTS® certified' accordionContent='accordion-content-gots' /> for the organic origin of the product, as well as environmentally and socially responsible processing. It's not only better for you, it's better for the planet.</>
        },
        hasScroll: true,
        svgId: 'icon-sustainability'
    },
    silkySoftYetDurable: {
        title: 'Silky-soft yet durable',
        description: `Long cotton fibers, or staples, are woven into silky-soft yet durable sheets that just get softer and more comfortable with every wash.`,
        svgId: 'icon-soft'
    },
    silkySoftYetDurableCotton: {
        title: `Silky-soft yet durable`,
        description: `Long organic cotton fibers are woven into a sateen weave to give you its distinctive subtle luster and beautiful drape.`,
        svgId: 'icon-soft'
    },
    warmYetDurable: {
        title: 'Warm yet breathable',
        description: `Long staple cotton fibers are woven into the most luxurious flannel textile in a heavyweight 180GSM that keeps you warm while still remaining breathable.`,
        svgId: 'icon-breathable'
    },
    yarnDyedSoftness: {
        title: 'Yarn dyed for softness',
        description: 'Our flannel cotton is individually yarn dyed for a unique heathered finish and a soft, lived-in feel.',
        svgId: 'icon-soft'
    },
    velvetySoftnessdurability: {
        title: 'Velvety softness & durability',
        description: 'Brushed long staple cotton imparts a rich, velvety finish that resists wear and pilling.',
        svgId: 'icon-durable'
    },
    madeFairTrade: {
        title: `Made with fair trade`,
        description (ScrollButton) {
            return <>Our bedding is made in <ScrollButton copy='Fair Trade Certified™ factories' accordionContent='accordion-content-accordion-title-fair-trade-certified-cotton' /> with the most rigorous standards of economic and social responsibility for their workers. We believe it's the most effective way for us to do the most good every day.</>
        },
        hasScroll: true,
        svgId: 'icon-world-good'
    },
    madeFairFactories: {
        title: `Made with fair trade`,
        description (ScrollButton) {
            return <>Our bedding is made in <ScrollButton copy='Fair Trade Certified™ factories' accordionContent='accordion-content-fair-trade-factories' /> with the most rigorous standards of economic and social responsibility for their workers. We believe it's the most effective way for us to do the most good every day.</>
        },
        hasScroll: true,
        svgId: 'icon-world-good'
    },
    softYetDurable: {
        title: 'Soft yet durable',
        description: 'Linen has a unique texture with natural slubs that give the finished fabric a thicker hand feel. The best part? Linen gets softer and takes on a more luxurious drape with every wash.',
        svgId: 'icon-soft'
    },
    premiunBelgianLinen: {
        title: '100% premium Belgian linen',
        description: 'Handcrafted from the highest quality Belgian flax, our linen is hypoallergenic, antimicrobial, breathable, and will retain its beauty for years.',
        svgId: 'icon-weave-linen'
    },
    premiumEuropeanLinen: {
        title: '100% premium European linen',
        description: 'Handcrafted from the highest quality European flax, our linen is hypoallergenic, antimicrobial, breathable, and will retain its beauty for years.',
        svgId: 'icon-weave-linen'
    },
    prewashedYarnDyed: {
        title: 'Prewashed and yarn dyed',
        description: `Our linen is prewashed for extra softness and a signature relaxed look. They're individually yarn dyed for a unique textured finish.`,
        svgId: 'icon-prewashed'
    },
    breathablePercaleCottonShell: {
        title: 'Breathable percale cotton shell',
        description: `Our 100% cotton shell is woven into a 200-thread count percale weave that's naturally very breathable and moisture-wicking.`,
        svgId: 'icon-breathable'
    },
    hypoallergenic: {
        title: '100% hypoallergenic',
        description: `Sensitive sleepers can rest easy with our comforter’s naturally hypoallergenic materials. The addition of natural lyocell in the fill makes it superbly moisture-wicking and breathable.`,
        svgId: 'icon-hypoallergenic'
    },
    prewashedLivedInFeel: {
        title: 'Prewashed for a lived-in feel',
        description: `Our cotton is prewashed for extra softness right out of the package.`,
        svgId: 'icon-prewashed'
    },
    versatileForAllSeasons: {
        title: 'Versatile for all seasons',
        description: `Ample knit construction offers the perfect balance of breathability and warmth and allows flexibility in bed placement.`,
        svgId: 'icon-breathable'
    },
    plushYetDurable: {
        title: 'Plush yet durable',
        description: `The heavyweight cotton velvet and durable percale cotton backing means you'll enjoy this quilt's cozy warmth for years to come.`,
        svgId: 'icon-soft'
    },
    prewashedSuperbSoftness: {
        title: 'Prewashed for superb softness',
        description: `Our luxurious velvet cotton is washed to give it an incredibly soft feel.`,
        svgId: 'icon-prewashed'
    },
    premiumEuropeanLinen: {
        title: '100% premium European linen',
        description: 'Handcrafted from the highest quality European flax, our linen is hypoallergenic, antimicrobial, breathable, and will retain its beauty for years.',
        svgId: 'icon-weave-linen' 
    },
}

const productValuePropsConfig = {
    // Mattresses
    [PC.SAATVA_CLASSIC]: {
        productValueProps: [
            sharedValueProps.luxurious,
            sharedValueProps.durable,
            sharedValueProps.supportive,
            sharedValueProps.breathable
        ]
    },
    [PC.SAATVA_LATEX_HYBRID]: {
        productValueProps: [
            sharedValueProps.pressureRelieving,
            sharedValueProps.responsiveLatex,
            sharedValueProps.excellentCooling,
            sharedValueProps.healthierSleep
        ]
    },
    [PC.MEMORY_FOAM_HYBRID]: {
        productValueProps: [
            sharedValueProps.bodyContouring,
            sharedValueProps.responsive,
            sharedValueProps.tripleCooling,
            sharedValueProps.backSupport
        ]
    },
    [PC.SAATVA_RX]: {
        productValueProps: [
            {
                svgId: 'icon-contouring',
                title: 'Body-contouring',
                list: [
                    'Cradles your body in weightless support to relieve pressure points',
                    'Micro-coils are more flexible than other spring types, conforming to every curve while providing stronger support in the center and perimeter of the mattress'
                ]
            },
            {
                svgId: 'icon-durable-mattress',
                title: 'Enhanced back support',
                list: [
                    'Get extra support where you need it most with our patented Lumbar Zone® Technology',
                    'Added lower back support and pressure relief with a gel-infused memory foam lumbar crown'
                ]
            },
            {
                svgId: 'icon-responsive',
                title: 'Responsive',
                list: [
                    'Automatically adjusts to your curves & movements',
                    'Reduces motion transfer for more undisturbed sleep'
                ]
            },
            {
                svgId: 'icon-breathable-mattress',
                title: 'Breathable',
                list: [
                    'Sleep cooler with our naturally breathable cover made with organic cotton',
                    'Breathable foams & dual-coil design allow for more airflow to disperse body heat'
                ]
            }
        ]
    },
    [PC.SOLAIRE]: {
        productValueProps: [
            {
                svgId: 'icon-personalized',
                title: 'Personalized comfort',
                list: ['50 firmness settings for your perfect comfort level in every sleep position', 'Add the <a class="t-link" href="/bases/adjustable-base-plus">Saatva Adjustable Base Plus</a> for even more comfort options at your fingertips']
            },
            {
                svgId: 'icon-motion-isolation',
                title: 'Great for couples',
                list: ['Never compromise on comfort again with adjustable firmness on both sides (queen size and larger)', 'Dual air chambers virtually eliminates motion transfer']
            },
            {
                svgId: 'icon-luxury',
                title: 'Luxurious',
                list: ['Pressure-relieving comfort layers cradle your body in perfect alignment', 'Wake up feeling restored with less aches and pains']
            },
            {
                svgId: 'icon-breathable-mattress',
                title: 'Breathable',
                list: ['Naturally breathable organic cotton and hypoallergenic latex', 'Heat-dispersing gel-infused memory foam helps you sleep cooler']
            }
        ]
    },
    [PC.LOOM_AND_LEAF]: {
        productValueProps: [
            {
                svgId: 'icon-pressure-relief',
                title: 'Pressure relief',
                list: [
                    'Gel-infused memory foams mold and contour to your curves, providing excellent pressure relief while keeping you cooler',
                    'Ultra-premium 5-lb memory foam core provides a deep, body-hugging feel balanced with optimal support'
                ]
            },
            {
                svgId: 'icon-lumbar-support',
                title: 'Back support',
                list: [
                    'Lumbar Zone® Quilting & gel-infused memory foam lumbar crown give you enhanced support where you need it most',
                    'Enjoy healthy spinal alignment in any sleep position'
                ]
            },
            {
                svgId: 'icon-breathable-mattress',
                title: 'Breathable',
                list: [
                    'Our naturally breathable cover made with organic cotton helps you sleep cooler',
                    'Gel-infused memory foam with micro perforations increases breathability'
                ]
            },
            {
                svgId: 'icon-world-good',
                hasScroll: true,
                title: 'Healthier sleep',
                list: [
                    {
                        description (ScrollButton) {
                            return <>Eco-friendly<ScrollButton copy='CertiPUR-US® certified' accordionContent='accordion-content-certipur' /> foams produce lower volatile organic compounds (VOCs) for healthier indoor air quality.</>
                        }
                    },
                    'Certified free of potentially harmful chemicals like ozone depleters, formaldehyde, and heavy metals'
                ]
            }
        ]
    },
    [PC.SAATVA_CONTOUR5]: {
        productValueProps: [
            {
                svgId: 'icon-pressure-relief',
                title: 'Pressure relief',
                list: [
                    'Unique combination of high-density memory foams adapts to your body weight and curves to immediately relieve pressure points',
                    'Plush 3” ultra-premium 5lb memory foam core and high-density base ensure maximum durability for years of comfort and support'
                ]
            },
            {
                svgId: 'icon-cooling',
                title: 'Adaptive cooling',
                list: [
                    '<span class="t-fontWeight--normal">CoolVent™ System</span> absorbs and disperses excess body heat to to help you fall asleep and stay asleep',
                    'Gel-infused 4lb memory foam with airflow channels means you never sleep hot'
                ]
            },
            {
                svgId: 'icon-contouring',
                title: 'Body-hugging feel',
                list: [
                    'Ultra-premium 5lb memory foam core gives the Contour5 its luxurious body-cradling comfort',
                    'Hugs every curve while keeping your spine in healthy alignment in any sleep position'
                ]
            },
            {
                svgId: 'icon-world-good',
                hasScroll: true,
                title: 'Healthier sleep',
                list: [
                    {
                        description (ScrollButton) {
                            return <>Eco-friendly<ScrollButton copy='CertiPUR-US® certified' accordionContent='accordion-content-certipur' /> foams produce lower volatile organic compounds (VOCs) for healthier indoor air quality.</>
                        }
                    },
                    {
                        description (ScrollButton) {
                            return <><ScrollButton copy='GREENGUARD® Gold certified' accordionContent='accordion-content-greenguard' /> natural flame barrier is screened for more than 10,000 chemicals and VOCs</>
                        }
                    }
                ]
            }
        ]
    },
    [PC.ZENHAVEN]: {
        productValueProps: [
            {
                svgId: 'icon-dual-sided',
                title: 'Dual sided',
                list: [
                    'Flippable to give you two distinct firmness options in one bed',
                    'Luxury Plush on one side, Gentle Firm on the other'
                ]
            },
            {
                svgId: 'icon-pressure-relief',
                title: 'Pressure relieving',
                list: [
                    '5-zone natural latex for excellent pressure relief and weightless comfort',
                    'Enhanced support of the lumbar spine in any sleep position'
                ]
            },
            {
                svgId: 'icon-cooling',
                title: 'Excellent cooling',
                list: [
                    'Naturally breathable, hypoallergenic cover made with organic cotton that sleeps cooler',
                    'Natural latex is highly breathable and organic New Zealand wool helps regulate body temperature'
                ]
            },
            {
                svgId: 'icon-healthier-sleep',
                hasScroll: true,
                title: 'Healthier sleep',
                list: [
                    {
                        description (ScrollButton) {
                            return <><ScrollButton copy='GOLS' accordionContent='accordion-content-gols' /> (Global Organic Latex Standard) certified to adhere to their strict limits on harmful chemicals, fillers, and emissions</>
                        }
                    },
                    {
                        description (ScrollButton) {
                            return <p><ScrollButton copy='GREENGUARD® Gold' accordionContent='accordion-content-greenguard' /> & <ScrollButton copy='eco-INSTITUT®' accordionContent='accordion-content-ecoinstitut' /> certified to have low VOCs (volatile organic compounds) and total chemical emissions</p>
                        }
                    }
                ]
            }
        ]
    },
    [PC.SAATVA_HD]: {
        productValueProps: [
            {
                svgId: 'icon-luxury',
                title: 'Luxurious',
                list: [
                    'Plush 3" Euro pillow top for instant cushioning & pressure relief',
                    'Unique combination of pillow top, buoyant latex foam, body-hugging memory foam & responsive coils makes this a "best of all worlds" mattress'
                ]
            },
            {
                svgId: 'icon-durable-mattress',
                title: 'Extra Durable',
                list: [
                    'Hand-tufted pillow top adds durability and helps prevent body impressions from forming over time',
                    'Tempered steel coils are 25% stronger than industry standard'
                ]
            },
            {
                svgId: 'icon-supportive',
                title: 'Extra Supportive',
                list: [
                    '5-zone natural latex & Lumbar Zone® Active Spinal Wire for enhanced support of the lumbar spine',
                    'High-density foam base and rails for stable edge support for sleepers up to 500 pounds'
                ]
            },
            {
                svgId: 'icon-breathable-mattress',
                title: 'Breathable',
                list: [
                    'Naturally breathable, hypoallergenic cover made with organic cotton that sleeps cooler',
                    'Ultra-breathable natural latex & individually wrapped coils promote great air flow'
                ]
            }
        ]
    },
    [PC.BUNK_TRUNDLE_YOUTH]: {
        productValueProps: [
            {
                title: 'Back Support',
                list: [
                    `Lumbar Zone® Quilting & gel-infused memory foam lumbar crown give you enhanced support where you need it most`,
                    'Enjoy healthy spinal alignment in any sleep position'
                ],
                svgId: 'icon-lumbar-support'
            },
            {
                title: 'Durable',
                list: [
                    `Polyfoam transition layer and insulator pad add comfort and durability you won’t find in cheaper bunk & trundle bed mattresses`,
                    'High-density base layer adds stable, resilient support for a longer-lasting mattress'
                ],
                svgId: 'icon-durable'
            },
            {
                title: 'Breathable',
                list: [
                    `Our naturally breathable cover made with organic cotton helps you sleep cooler`,
                    'Coil layer allows more airflow throughout to help disperse body heat'
                ],
                svgId: 'icon-breathable-mattress'
            },
            sharedValueProps.goodForTheWorld
        ]
    },
    [PC.SAATVA_YOUTH]: {
        productValueProps: [
            {
                title: 'Dual sided',
                list: [
                    'Flippable to adapt to your child’s needs as they grow',
                    'Plush for ages 3-7 on one side, Medium-Firm for ages 8-12 on the other'
                ],
                svgId: 'icon-dual-sided'
            },
            {
                title: 'Accident-proof',
                list: [
                    'Water resistant finish on the breathable cover made with organic cotton'
                ],
                svgId: 'icon-water-proof'
            },
            {
                title: 'Healthier sleep',
                list: [
                    {
                        description (ScrollButton) {
                            return <>Eco-friendly <ScrollButton copy='CertiPUR-US®' accordionContent='accordion-content-certipur' /> certified foams produce lower volatile organic compounds (VOCs) for healthier indoor air quality</>
                        }
                    },
                    'Natural thistle flame barrier, NO fiberglass or toxic chemical sprays added'
                ],
                svgId: 'icon-healthier-sleep'
            }
        ]
    },
    [PC.CRIB_MATTRESS]: {
        productValueProps: [
            {
                title: 'Dual sided',
                list: [
                    'Flippable to adapt to your child’s needs as they grow',
                    'Extra-firm support on the baby side, firm on the toddler side'
                ],
                svgId: 'icon-dual-sided'
            },
            {
                title: 'Healthier sleep',
                list: [
                    {
                        description (ScrollButton) {
                            return <><ScrollButton copy='GREENGUARD®' accordionContent='accordion-content-greenguard' /> Gold certified, <ScrollButton copy='Cradle to Cradle®' accordionContent='accordion-content-cradle-to-cradle-gold-certified' /> GOLD Certified & <ScrollButton copy='GOTS®' accordionContent='accordion-content-gots' /> certified organic wool</>
                        }
                    },
                    'Natural thistle flame barrier, NO fiberglass or toxic chemical sprays added'
                ],
                svgId: 'icon-healthier-sleep'
            },
            {
                title: 'Accident-proof',
                list: [
                    'Water-resistant finish on the cotton cover for easy cleanups',
                    'Waterproof barrier helps protect the internal layers against accidents & spills'
                ],
                svgId: 'icon-water-proof'
            },
            {
                title: 'Breathable',
                list: [
                    `Naturally breathable, hypoallergenic cover made with organic cotton that sleeps cooler`,
                    'Organic New Zealand wool helps regulate body temperature'
                ],
                svgId: 'icon-breathable-mattress'
            }
        ]
    },
    [PC.MURPHY_BED_MATTRESS]: {
        productValueProps: [
            {
                title: 'Back support',
                list: [
                    'LumbarZone® Technology gives you enhanced support where you need it most',
                    'Enjoy healthy spinal alignment in any sleep position'
                ],
                svgId: 'icon-lumbar-support'
            },
            {
                title: 'Durable',
                list: [
                    'Dense poly foam adds transitional support between the layers',
                    'High-density foam rails add stable, firmer edge support for better shape retention'
                ],
                svgId: 'icon-durable'
            },
            {
                title: 'Breathable',
                list: [
                    'Our naturally breathable cover made with organic cotton helps you sleep cooler',
                    'Coil layer allows more airflow throughout to help disperse body heat'
                ],
                svgId: 'icon-breathable-mattress'
            },
            sharedValueProps.goodForTheWorld
        ]
    },
    // Bedding
    [PC.LATEX_MATTRESS_TOPPER]: {
        productValueProps: [
            {
                title: 'Bouyant, weightless feel',
                description: 'Achieve more luxurious sleep instantly with the unmatched pressure relief and unique buoyant feel of natural latex.',
                svgId: 'icon-buoy'
            },
            {
                title: 'Eco-friendly & nontoxic latex',
                description (ScrollButton) {
                    return <>Our natural latex is <ScrollButton copy='eco-INSTITUT®' accordionContent='accordion-content-ecoinstitut' /> certified to be free of potentially harmful chemicals, emissions, and pollutants. It’s also <ScrollButton copy='Forest Stewardship Council' accordionContent='accordion-content-forestStewardship' /> (FSC) certified for meeting all of their sustainability standards.</>
                },
                hasScroll: true,
                svgId: 'icon-sustainability'
            },
            sharedValueProps.secureFit
        ]
    },
    [PC.GRAPHITE_MEMORY_FOAM_MATTRESS_TOPPER]: {
        productValueProps: [
            {
                title: 'Superb cooling & pressure relief',
                description: 'Achieve cooler, more comfortable sleep instantly with the body-hugging support, minimized motion transfer & pressure relief of memory foam infused with naturally cooling graphite.',
                svgId: 'icon-cooling'
            },
            sharedValueProps.ecoFriendly,
            sharedValueProps.secureFit
        ]
    },
    [PC.HIGH_DENSITY_FOAM_MATTRESS_TOPPER]: {
        productValueProps: [
            {
                title: 'Instant support & comfort',
                description: 'Achieve better sleep instantly with a contouring feel, pressure relief, and the perfect medium firmness for all sleep positions.',
                svgId: 'icon-lumbar-support'
            },
            sharedValueProps.ecoFriendly,
            sharedValueProps.secureFit
        ]
    },
    [PC.MICROCOIL_MATTRESS_TOPPER]: {
        productValueProps: [
            {
                title: 'Responsive & pressure-relieving support',
                description: 'A hybrid of responsive micro-coils with enhanced lumbar support & body-contouring comfort foam helps relieve pressure points so you get more restful sleep and wake up with less aches and pains.',
                svgId: 'icon-supportive'
            },
            sharedValueProps.ecoFriendly,
            sharedValueProps.secureFit
        ]
    },
    [PC.FEATHERBED_MATTRESS_TOPPER]: {
        productValueProps: [
            {
                title: 'Ultra-plush yet breathable',
                description: 'A substantial 700GSM fill weight gives our featherbed topper a soft, cloud-like feel and the box quilting ensures the airy down alternative & lyocell fill is evenly distributed for ultimate plushness.',
                svgId: 'icon-fluffy'
            },
            {
                title: 'Healthier sleep',
                description (ScrollButton) {
                    return <>This product is <ScrollButton copy='OEKO-TEX® Standard 100' accordionContent='accordion-content-what-is-oekotex' /> as having passed safety tests for the presence of harmful substances.</>
                },
                hasScroll: true,
                svgId: 'icon-healthier-sleep'
            },
            sharedValueProps.secureFit
        ]
    },
    [PC.ORGANIC_QUILTED_MATTRESS_TOPPER]: {
        productValueProps: [
            {
                title: 'Buoyant, weightless feel',
                description: 'Achieve more luxurious sleep instantly with the unmatched pressure relief and unique buoyant feel of natural latex.',
                svgId: 'icon-buoy'
            },
            {
                title: 'Luxurious sleep surface',
                description: 'Cover made with naturally breathable and moisture-wicking organic cotton and box-tailored with a gusseted double-edge welting, similar to our handcrafted mattresses.',
                svgId: 'icon-luxury'
            },
            {
                title: 'Organic all the way',
                description (ScrollButton) {
                    return <><ScrollButton copy='GOTS® certified' accordionContent='accordion-content-gots' /> organic latex, wool & cotton cover are free from toxic chemicals and every step of production meets the strictest social and environmental criteria. It’s not only better for you, it’s better for the planet.</>
                },
                hasScroll: true,
                svgId: 'icon-sustainability'
            },
            {
                title: 'Seamless design',
                description: 'Our organic topper easily fits on top of any mattress, new or old, without the need for straps. Simply place over your mattress and add your fitted sheet for a seamless fit.',
                svgId: 'icon-fit'
            }
        ]
    },
    [PC.WAFFLE_KNIT_BLANKET]: {
        productValueProps: [
            {
                title: 'Plush yet lightweight',
                description: 'Classic waffle weave, with a medium-weight 350 GSM, offers a soft, springy feel that’s cozy yet airy and adds an elegant dose of texture to any bedroom.',
                svgId: 'icon-lightweight'
            },
            {
                title: 'Temperature regulating',
                description: 'Thick, three-dimensional outline of the weave helps to retain warmth for a cozy feel, while square pockets allow air to circulate for maximum breathability, even in warmer months.',
                svgId: 'icon-breathable'
            },

            {
                title: 'Healthier sleep',
                description (ScrollButton) {
                    return <>This product is <ScrollButton copy='OEKO-TEX® Standard 100' accordionContent='accordion-content-what-is-oekotex' /> as having passed safety tests for the presence of harmful substances.</>
                },
                hasScroll: true,
                svgId: 'icon-healthier-sleep'
            }
        ]
    },
    [PC.SWEATER_KNIT_BLANKET]: {
        productValueProps: [
            {
                title: 'Soft, springy feel',
                description: 'Modern moss-knit weave offers a cozy softness that’s reminiscent of your favorite sweater, complete with hand-finished details.',
                svgId: 'icon-soft'
            },
            {
                title: 'Warm but never stuffy',
                description: 'Dense, tight-loop structure offers excellent drape and heat retention, while its 460 GSM adds luxuriously plush weight while still staying breathable.',
                svgId: 'icon-sun'
            },
            {
                title: 'Healthier sleep',
                description (ScrollButton) {
                    return <>This product is <ScrollButton copy='OEKO-TEX® Standard 100' accordionContent='accordion-content-what-is-oekotex' /> as having passed safety tests for the presence of harmful substances.</>
                },
                hasScroll: true,
                svgId: 'icon-healthier-sleep'
            }
        ]
    },
    [PC.WAFFLE_KNIT_DUVET_COVER_SET]: {
        heading: 'waffle knit duvet cover sets',
        productValueProps: [
            {
                title: 'Plush yet lightweight',
                description: 'Classic waffle weave offers a soft, springy feel that’s cozy yet airy and adds an elegant dose of texture to any bedroom.',
                svgId: 'icon-lightweight'
            },
            {
                title: 'Temperature regulating',
                description: 'Thick, three-dimensional outline of the weave helps to retain warmth for a cozy feel, while square pockets allow air to circulate for maximum breathability, even in warmer months.',
                svgId: 'icon-breathable'
            },
            {
                title: `Just the right thread count`,
                description: `As thread counts rise, fabrics become warmer. The percale cotton backing has a thread count of 200 for the perfect balance of softness and breathability.`,
                svgId: 'icon-thread'
            },
            {
                title: 'Healthier sleep',
                description (ScrollButton) {
                    return <>This product has been <ScrollButton copy='OEKO-TEX® Standard 100' accordionContent='accordion-content-what-is-oekotex' /> certified to guarantee that it’s free of harmful chemicals.</>
                },
                hasScroll: true,
                svgId: 'icon-healthier-sleep'
            }
        ]
    },
    [PC.EMBROIDERED_HOTEL_STYLE_SHEETS]: {
        productValueProps: [
            sharedValueProps.silkySoftYetDurable,
            sharedValueProps.rightThreadCount300,
            sharedValueProps.ethicallySourcedCotton,
            sharedValueProps.oekotex
        ]
    },
    [PC.EMBROIDERED_SATEEN_DUVET_SET]: {
        heading: 'sateen duvet cover sets',
        productValueProps: [
            sharedValueProps.silkySoftYetDurable,
            sharedValueProps.rightThreadCount300,
            sharedValueProps.ethicallySourcedCotton,
            sharedValueProps.oekotex
        ]
    },
    [PC.PERCALE_DUVET_COVER_SET]: {
        heading: 'percale duvet cover sets',
        productValueProps: [
            {
                svgId: 'icon-lightweight',
                title: 'Light yet durable',
                description: `Long cotton fibers are woven into a crisp percale weave that's naturally very breathable, moisture-wicking, and cool to the touch.`
            },
            sharedValueProps.rightThreadCount,
            sharedValueProps.ethicallySourcedCotton,
            sharedValueProps.oekotex
        ]
    },
    [PC.ORGANIC_SATEEN_DUVET_COVER_SET]: {
        heading: 'sateen duvet cover sets',
        productValueProps: [
            sharedValueProps.silkySoftYetDurableCotton,
            sharedValueProps.rightThreadCount300,
            sharedValueProps.madeFairFactories,
            sharedValueProps.organicAllTheWay
        ]
    },
    [PC.ESSENTIAL_DUVET_COVER_SET]: {
        heading: 'essential duvet cover set',
        productValueProps: [
            {
                title: 'Superbly soft & smooth feel',
                description: `Long percale cotton fibers, or staples, are woven with eco-friendly viscose for softness, a smooth hand feel and added wrinkle resistance.`,
                svgId: 'icon-weave'
            },
            {
                title: 'Just the right thread count',
                description: 'As thread counts rise, fabrics become warmer. We chose a thread count of 180 for superb breathability while maintaining durability.',
                svgId: 'icon-thread'
            },
            {
                title: 'Healthier sleep',
                description (ScrollButton) {
                    return <>We only use <ScrollButton copy='OEKO-TEX® Standard 100' accordionContent='accordion-content-what-is-oekotex' /> certified cotton that has passed safety tests for the presence of harmful substances. Natural cotton is also moisture-wicking and hypoallergenic.</>
                },
                hasScroll: true,
                svgId: 'icon-healthier-sleep'
            }
        ]
    },
    [PC.SIGNATURE_DUVET_COVER_SET]: {
        heading: 'Signature Duvet Cover Sets',
        productValueProps: [
            sharedValueProps.silkySoftYetDurable,
            sharedValueProps.rightThreadCount300,
            sharedValueProps.ethicallySourcedCotton,
            sharedValueProps.oekotex
        ]
    },
    [PC.WATERPROOF_MATTRESS_PROTECTOR]: {
        heading: 'Waterproof Mattress Protector',
        productValueProps: [
            {
                title: 'Breathable protection',
                description: 'Ultra-thin polyurethane moisture barrier protects your mattress from liquid spills for hours, while micro-perforations allow air to flow through.',
                svgId: 'icon-breathable'
            },
            {
                title: 'Secure fit',
                description: 'Cotton lining, deep pockets, and a silicone elastic band all the way around ensure that your mattress protector stays put.',
                svgId: 'icon-fit'
            },
            {
                title: 'Healthier sleep',
                description (ScrollButton) {
                    return <>This product is <ScrollButton copy='OEKO-TEX® Standard 100' accordionContent='accordion-content-what-is-oekotex' /> as having passed safety tests for the presence of harmful substances.</>
                },
                hasScroll: true,
                svgId: 'icon-healthier-sleep'
            }
        ]
    },
    [PC.ORGANIC_MATTRESS_PAD]: {
        productValueProps: [
            {
                title: 'Breathable protection',
                description: 'Sleep in optimal coolness & comfort with 300 thread count organic cotton.',
                svgId: 'icon-breathable'
            },
            {
                title: 'Secure fit',
                description: 'Side panels are woven with 4% spandex and finished with a wide silicone-elastic band.',
                svgId: 'icon-fit'
            },
            sharedValueProps.organicAllTheWay
        ]
    },
    [PC.SIGNATURE_MATTRESS_PAD]: {
        productValueProps: [
            {
                title: 'Breathable protection',
                description: 'Sleep in optimal coolness & comfort with breathable percale cotton.',
                svgId: 'icon-breathable'
            },
            {
                title: 'Secure fit',
                description: 'Side panels are woven with 4% spandex and finished with a wide silicone-elastic band.',
                svgId: 'icon-fit'
            },
            {
                title: 'Healthier sleep',
                description (ScrollButton) {
                    return <>We only use <ScrollButton copy='OEKO-TEX® Standard 100' accordionContent='accordion-content-what-is-oekotex' /> certified linen that has passed safety tests for the presence of harmful substances. Natural cotton is also moisture-wicking and hypoallergenic.</>
                },
                hasScroll: true,
                svgId: 'icon-healthier-sleep'
            }

        ]
    },
    [PC.PERCALE_SHEET_SET]: {
        productValueProps: [
            {
                svgId: 'icon-weave-plain',
                title: 'Light yet durable',
                description: `Long cotton fibers, or staples, are woven into a crisp percale weave that's naturally very breathable, moisture-wicking, and cool to the touch.`
            },,
            sharedValueProps.rightThreadCount,
            sharedValueProps.ethicallySourcedCotton,
            sharedValueProps.oekotex
        ]
    },
    [PC.ORGANIC_COTTON_SHEETS]: {
        productValueProps: [
            sharedValueProps.silkySoftYetDurableCotton,
            sharedValueProps.rightThreadCount300,
            sharedValueProps.madeFairTrade,
            sharedValueProps.organicAllTheWay
        ]
    },
    [PC.LINEN_SHEET_SET]: {
        productValueProps: [
            sharedValueProps.softYetDurable,
            sharedValueProps.premiumEuropeanLinen,
            sharedValueProps.prewashedYarnDyed,
            {
                title: 'Healthier sleep',
                description (ScrollButton) {
                    return <>We only use <ScrollButton copy='OEKO-TEX® Standard 100' accordionContent='accordion-content-what-is-oekotex' /> certified linen that has passed safety tests for the presence of harmful substances.</>
                },
                hasScroll: true,
                svgId: 'icon-healthier-sleep'
            }
        ]
    },
    [PC.SATEEN_COTTON_SHEET_SET]: {
        productValueProps: [
            sharedValueProps.silkySoftYetDurable,
            sharedValueProps.rightThreadCount300,
            sharedValueProps.ethicallySourcedCotton,
            sharedValueProps.oekotex
        ]
    },
    [PC.FLANNEL_SHEET_SET]: {
        productValueProps: [
            sharedValueProps.warmYetDurable,
            sharedValueProps.yarnDyedSoftness,
            sharedValueProps.velvetySoftnessdurability,
            {
                title: 'Healthier sleep',
                description (ScrollButton) {
                    return <>We only use <ScrollButton copy='OEKO-TEX® Standard 100' accordionContent='accordion-content-what-is-oekotex' /> certified cotton that has passed safety tests for the presence of harmful substances.</>
                },
                hasScroll: true,
                svgId: 'icon-healthier-sleep'
            }
        ]
    },
    [PC.BANDED_PERCALE_SHEET_SET]: {
        productValueProps: [
            {
                svgId: 'icon-weave-plain',
                title: 'Light yet durable',
                description: `Long cotton fibers, or staples, are woven into a crisp percale weave that's naturally very breathable, moisture-wicking, and cool to the touch`
            },
            sharedValueProps.rightThreadCount,
            sharedValueProps.oekotex
        ]
    },
    [PC.DOWN_ALTERNATIVE_COMFORTER]: {
        heading: 'down alternative comforter',
        productValueProps: [
            sharedValueProps.breathablePercaleCottonShell,
            {
                title: 'Cozy, not stuffy',
                description: `A cloud-like blend of down alternative fibers and natural lyocell fill with a mid-weight 340 GSM keeps you warm and cozy yet stays airy and breathable.`,
                svgId: 'icon-fluffy'
            },
            sharedValueProps.hypoallergenic,
            sharedValueProps.ethicallySourcedCotton
        ]
    },
    [PC.LIGHTWEIGHT_DOWN_ALTERNATIVE_COMFORTER]: {
        heading: 'lightweight down alternative comforter',
        productValueProps: [
            sharedValueProps.breathablePercaleCottonShell,
            {
                title: 'Cozy yet lightweight',
                description: `Breathable blend of down alternative fibers and natural lyocell fill with a lightweight 220 GSM is perfect for those who sleep hot.`,
                svgId: 'icon-fluffy'
            },
            sharedValueProps.ethicallySourcedCotton,
            sharedValueProps.hypoallergenic
        ]
    },
    [PC.HEAVYWEIGHT_COMFORTER]: {
        heading: 'heavyweight down alternative comforter',
        productValueProps:[
            sharedValueProps.breathablePercaleCottonShell,
            {
                title: 'Warm without overheating',
                description: `Heavier fill of down alternative fibers and natural lyocell plus a thicker 420 GSM cotton shell gives it the perfect blend of warmth and cloud-like airiness.`,
                svgId: 'icon-fluffy'
            },
            sharedValueProps.hypoallergenic,
            sharedValueProps.oekotex
        ]
    },
    [PC.LINEN_DUVET_COVER_SET]: {
        heading: 'linen duvet cover sets',
        productValueProps:[
            sharedValueProps.softYetDurable,
            sharedValueProps.premiumEuropeanLinen,
            sharedValueProps.prewashedYarnDyed,
            {
                title: 'Healthier sleep',
                description (ScrollButton) {
                    return <>We only use <ScrollButton copy='OEKO-TEX® Standard 100' accordionContent='accordion-content-what-is-oekotex' /> certified linen that has passed safety tests for the presence of harmful substances.</>
                },
                hasScroll: true,
                svgId: 'icon-healthier-sleep'
            }
        ]
    },
    [PC.BANDED_PERCALE_DUVET_SET]: {
        heading: 'banded percale duvet cover sets',
        productValueProps:[
            sharedValueProps.lightYetDurable,
            sharedValueProps.rightThreadCount,
            sharedValueProps.ethicallySourcedCotton,
            sharedValueProps.oekotex
        ]
    },
    [PC.WEIGHTED_BLANKET]: {
        productValueProps:[
            {
                title: 'Promotes restful sleep',
                description: `Our weighted blanket has a soothing, calming effect. The feeling is similar to being hugged, a science-backed phenomenon known as “deep pressure stimulation".`,
                svgId: 'icon-diamond-quilt'
            },
            {
                title: 'Soft, cozy, and breathable',
                description: `Natural cotton velvet is soft to the touch for maximum comfort and warmth. Gentle, even pressure from all-natural glass beads encourages relaxation.`,
                svgId: 'icon-soft'
            },
            sharedValueProps.ethicallySourcedCotton,
            {
                title: 'Healthier sleep',
                hasScroll: true,
                description (ScrollButton) {
                    return <>This product is <ScrollButton copy='OEKO-TEX® Standard 100' accordionContent='accordion-content-what-is-oekotex' /> as having passed safety tests for the presence of harmful substances.</>
                },
                svgId: 'icon-healthier-sleep'
            }
        ]
    },
    [PC.DIAMOND_KNIT_BLANKET]: {
        productValueProps:[
            {
                title: 'Light yet durable',
                description: `Luxurious long cotton fibers, or staples, are woven into a geometric knit that's naturally hypoallergenic, antimicrobial, and very breathable.`,
                svgId: 'icon-lightweight'
            },
            sharedValueProps.prewashedLivedInFeel,
            sharedValueProps.versatileForAllSeasons,
            {
                title: 'Healthier sleep',
                hasScroll: true,
                description (ScrollButton) {
                    return <>This product is <ScrollButton copy='OEKO-TEX® Standard 100' accordionContent='accordion-content-what-is-oekotex' /> as having passed safety tests for the presence of harmful substances.</>
                },
                svgId: 'icon-healthier-sleep'
            }
        ]
    },
    [PC.ORGANIC_COTTON_CHANNEL_QUILT]: {
        productValueProps: [
            sharedValueProps.silkySoftYetDurableCotton,
            sharedValueProps.rightThreadCount300,
            {
                title: 'Channel quilt pattern',
                description: `The refined 1” channel stitch pattern lends a relaxed, cozy feel to the quilt and shams that never goes out of style.`,
                svgId: 'icon-channel-quilt'
            },
            sharedValueProps.organicAllTheWay
        ]
    },
    [PC.ORGANIC_VELVET_QUILT]: {
        productValueProps: [
            sharedValueProps.plushYetDurable,
            sharedValueProps.prewashedSuperbSoftness,
            {
                title: 'Diamond quilt pattern',
                description: `The refined 1” diamond stitch pattern lends a classic tailored look and a cozy thickness to the quilt.`,
                svgId: 'icon-diamond-quilt'
            },
            sharedValueProps.organicAllTheWay
        ]
    },
    [PC.AERO_QUILT]: {
        productValueProps: [
            {
                title: 'Light as air',
                description: `Proprietary cotton-viscose blend is woven into a lightweight gauze for coziness and warmth without the bulk.`,
                svgId: 'icon-cotton'
            },
            {
                title: 'Irresistibly soft & plush',
                description: `Combined with a thin layer of hypoallergenic poly fill, the gauze weave takes on a cozy-soft feel and a relaxed, lived-in look.`,
                svgId: 'icon-soft'
            },
            {
                title: 'Extremely breathable',
                description: `Cotton-viscose blend offers superior breathability and versatility year round.`,
                svgId: 'icon-breathable'
            },
            {
                title: 'Healthier sleep',
                description (ScrollButton) {
                    return <>This product is <ScrollButton copy='OEKO-TEX® Standard 100' accordionContent='accordion-content-what-is-oekotex' /> as having passed safety tests for the presence of harmful substances.</>
                },
                hasScroll: true,
                svgId: 'icon-healthier-sleep'
            }
        ]
    },
    [PC.HERRINGBONE_KNIT_BLANKET]: {
        productValueProps: [
            {
                title: 'Substantial yet breathable',
                description: `Luxurious long cotton fibers are woven into a medium-weight herringbone knit that keeps you comfortably warm but never stuffy.`,
                svgId: 'icon-breathable'
            },
            {
                title: 'Prewashed for a lived-in feel',
                description: `Our cotton is prewashed for a soft feel right out of the package and gets softer with every wash.`,
                svgId: 'icon-prewashed'
            },
            {
                title: 'Versatile for all seasons',
                description: `Ample knit construction offers the perfect balance of breathability and warmth for multiple ways to enjoy its cozy feel.`,
                svgId: 'icon-soft'
            },
            {
                title: 'Healthier sleep',
                description (ScrollButton) {
                    return <>This product is <ScrollButton copy='OEKO-TEX® Standard 100' accordionContent='accordion-content-what-is-oekotex' /> as having passed safety tests for the presence of harmful substances.</>
                },
                hasScroll: true,
                svgId: 'icon-healthier-sleep'
            }
        ]
    },
    [PC.VELVET_PICK_STICH_QUILT]: {
        headingL: 'Velvet Pick Stitch Quilt',
        productValueProps: [
            sharedValueProps.plushYetDurable,
            sharedValueProps.prewashedSuperbSoftness,
            sharedValueProps.oekotex
        ]
    },
    // Towels
    [PC.WAFFLE_TOWELS]: {
        productValueProps: [
            {
                title: 'Breathable yet absorbent',
                description: 'Features an Imabari-style waffle design for a lightweight feel that doesn’t sacrifice absorbency and softness.',
                svgId: 'icon-lightweight'
            },
            {
                title: 'Woven with durable long staple cotton',
                description: 'Long staple cotton yarns get softer with every wash and will last for years with proper care.',
                svgId: 'icon-cotton'
            },
            {
                title: `Better for you & the planet`,
                description (ScrollButton) {
                    return <><ScrollButton copy='OEKO-TEX® Standard 100' accordionContent='accordion-content-what-is-oekotex' /> certified cotton guarantees that our towels are free of harmful chemicals.</>
                },
                hasScroll: true,
                svgId: 'icon-world-good'
            }
        ]
    },
    [PC.PLUSH_TOWELS]: {
        productValueProps: [
            {
                title: 'Plush yet lightweight',
                description: 'Features an extra-long terry weave for a thick, plush feel and ultimate absorbency.',
                svgId: 'icon-fluffy'
            },
            {
                title: 'Woven with durable long staple cotton',
                description: 'Long staple cotton yarns get softer with every wash and will last for years with proper care.',
                svgId: 'icon-cotton'
            },
            {
                title: `Better for you & the planet`,
                description (ScrollButton) {
                    return <><ScrollButton copy='OEKO-TEX® Standard 100' accordionContent='accordion-content-what-is-oekotex' /> certified cotton guarantees that our towels are free of harmful chemicals.</>
                },
                hasScroll: true,
                svgId: 'icon-world-good'
            }
        ]
    },
    [PC.ESSENTIAL_SHEET_SET]: {
        productValueProps: [
            {
                title: 'Superbly soft & smooth feel',
                description: `Long percale cotton fibers, or staples, are woven with eco-friendly viscose for softness, a smooth hand feel and added wrinkle resistance.`,
                svgId: 'icon-weave'
            },
            {
                title: `Just the right thread count`,
                description: `As thread counts rise, fabrics become warmer. We chose a thread count of 180 for superb breathability while maintaining durability.`,
                svgId: 'icon-thread'
            },
            sharedValueProps.oekotex
        ]
    },
    // Pillowcases and Shams
    [PC.ESSENTIAL_PILLOWCASES]: {
        productValueProps: [
            {
                title: 'Superbly soft & smooth feel',
                description: `Long percale cotton fibers, or staples, are woven with eco-friendly viscose for softness, a smooth hand feel and added wrinkle resistance.`,
                svgId: 'icon-weave'
            },
            {
                title: `Just the right thread count`,
                description: `As thread counts rise, fabrics become warmer. We chose a thread count of 180 for superb breathability while maintaining durability.`,
                svgId: 'icon-thread'
            },
            sharedValueProps.oekotex 
        ]
    },
    [PC.SATEEN_COTTON_PILLOWCASES]: {
        productValueProps: [
            sharedValueProps.silkySoftYetDurable,
            sharedValueProps.rightThreadCount300,
            sharedValueProps.ethicallySourcedCotton,
            sharedValueProps.oekotex
        ]
    },
    [PC.BANDED_PERCALE_PILLOWCASES]: {
        productValueProps: [
            {
                title: 'Light yet durable',
                description: `Long organic cotton fibers, or staples, are woven into a crisp percale weave that's naturally very breathable, moisture-wicking, and cool to the touch.`,
                svgId: 'icon-weave-plain'
            },
            sharedValueProps.rightThreadCount,
            sharedValueProps.oekotex
        ]
    },
    [PC.FLANNEL_PILLOWCASES]: {
        productValueProps: [
            sharedValueProps.warmYetDurable,
            sharedValueProps.yarnDyedSoftness,
            sharedValueProps.velvetySoftnessdurability,
            {
                title: 'Healthier sleep',
                description (ScrollButton) {
                    return <>We only use <ScrollButton copy='OEKO-TEX® Standard 100' accordionContent='accordion-content-what-is-oekotex' /> certified cotton that has passed safety tests for the presence of harmful substances.</>
                },
                hasScroll: true,
                svgId: 'icon-healthier-sleep'
            }
        ]
    },
    [PC.ORGANIC_COTTON_PILLOWCASES]: {
        productValueProps: [
            sharedValueProps.silkySoftYetDurableCotton,
            sharedValueProps.rightThreadCount300,
            sharedValueProps.madeFairTrade,
            sharedValueProps.organicAllTheWay
        ]
    },
    [PC.PERCALE_PILLOWCASES]: {
        productValueProps: [
            {
                title: 'Light yet durable',
                description: `Long cotton fibers, or staples, are woven into a crisp percale weave that's naturally very breathable, moisture-wicking, and cool to the touch.`,
                svgId: 'icon-weave-plain'
            },
            sharedValueProps.rightThreadCount,
            sharedValueProps.ethicallySourcedCotton,
            sharedValueProps.oekotex
        ]
    },
    [PC.EMBROIDERED_PILLOWCASES]: {
        productValueProps: [
            sharedValueProps.silkySoftYetDurable,
            sharedValueProps.rightThreadCount300,
            sharedValueProps.ethicallySourcedCotton,
            sharedValueProps.oekotex
        ]
    },
    [PC.LINEN_PILLOWCASES]: {
        productValueProps: [
            sharedValueProps.softYetDurable,
            sharedValueProps.premiumEuropeanLinen,
            sharedValueProps.prewashedYarnDyed,
            {
                title: 'Healthier sleep',
                description (ScrollButton) {
                    return <>We only use <ScrollButton copy='OEKO-TEX® Standard 100' accordionContent='accordion-content-what-is-oekotex' /> certified linen that has passed safety tests for the presence of harmful substances.</>
                },
                hasScroll: true,
                svgId: 'icon-healthier-sleep'
            }
        ]
    },
    
    // Foundation and Bed frames
    [PC.FOUNDATION]: {
        productValueProps: [
            {
                title: 'Durable support',
                list: [
                    'Premium quality materials provide a stable surface for your mattress',
                    'Optimal support promotes proper spinal alignment and even weight distribution to prevent sagging over time',
                    'Maximizes comfort and prolongs the lifespan of your mattress'

                ],
                svgId: 'icon-durable-mattress'
            },
            {
                title: 'Healthier sleep',
                hasScroll: true,
                list: [
                    {
                        description (ScrollButton) {
                            return <>Eco-friendly <ScrollButton copy='CertiPUR-US®' accordionContent='accordion-content-certipur' /> certified foams produce lower volatile organic compounds (VOCs) for healthier indoor air quality</>
                        }
                    },
                    'Cover made with the same organic cotton as our luxury mattresses'

                ],
                svgId: 'icon-world-good'
            },
            {
                title: 'Assembled in the U.S.',
                list: [
                    `Proudly made to order in our network of 14 U.S. factories, just like our luxury mattresses`,
                    'Domestic factories cut down on the overall carbon footprint from manufacturing to delivery'
                ],
                svgId: 'icon-made-in-usa'
            }
        ]
    },
    // Eye Masks
    [PC.SILK_EYE_MASK]: {
        productValueProps: [
            {
                title: '100% pure mulberry silk',
                description: 'Our high-quality mulberry silk is made of long fibers for a more refined feel. It contains natural proteins and amino acids to help soothe the delicate skin around the eyes.',
                svgId: 'icon-silk'
            },
            {
                title: 'Naturally breathable',
                description: 'Lightweight silk helps insulate the skin to prevent moisture loss. Its smooth, frictionless surface is also gentle on your skin.',
                svgId: 'icon-breathable'
            },
            {
                title: 'Smooth yet durable',
                description: 'We chose 22 momme silk for its perfect balance of durability and smooth hand feel. “Momme” is the unit used to describe the weight of silk thread.',
                svgId: 'icon-lightweight'
            },
            {
                title: 'Luxurious feel',
                description: 'Extra-smooth charmeuse weave creates a frictionless surface to prevent wrinkles and creases on the skin.',
                svgId: 'icon-spool'
            }
        ]
    },
    [PC.WEIGHTED_SILK_EYE_MASK]: {
        productValueProps: [
            {
                title: '100% pure mulberry silk',
                description: 'Our high-quality mulberry silk is made of long fibers for a more refined feel. It contains natural proteins and amino acids to help soothe the delicate skin around the eyes.',
                svgId: 'icon-silk'
            },
            {
                title: 'Naturally breathable',
                description: 'Lightweight, breathable silk helps insulate the skin to prevent moisture loss.',
                svgId: 'icon-breathable'
            },
            {
                title: 'Smooth yet durable',
                description: 'We chose 22 momme silk for its perfect balance of durability and smooth hand feel. “Momme” is the unit used to describe the weight of silk thread.',
                svgId: 'icon-lightweight'
            },
            {
                title: 'Luxurious feel',
                description: 'Extra-smooth charmeuse weave creates a frictionless surface to prevent wrinkles and creases on the skin.',
                svgId: 'icon-spool'
            }
        ]
    },
    [PC.SILK_PILLOWCASE]: {
        headingL: 'Velvet Pick Stitch Quilt',
        productValueProps: [
            {
                title: '100% pure mulberry silk',
                description: 'Our high-quality mulberry silk is made of long fibers for a more refined feel. It contains natural proteins and amino acids to help soothe skin and hair.',
                svgId: 'icon-silk'
            },
            {
                title: 'Naturally breathable',
                description: 'Lightweight silk helps insulate the skin to prevent moisture loss. Its smooth, frictionless surface is also gentle on your skin and hair.',
                svgId: 'icon-breathable'
            },
            {
                title: 'Smooth yet durable',
                description: 'We chose 22 momme silk for its perfect balance of durability and smooth hand feel. “Momme” is the unit used to describe the weight of silk thread.',
                svgId: 'icon-lightweight'
            },
            {
                title: 'Luxurious feel',
                description: 'Extra-smooth charmeuse weave creates a frictionless surface to prevent wrinkles and creases on the skin.',
                svgId: 'icon-spool'
            }
        ]
    },
}

export default productValuePropsConfig
